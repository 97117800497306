<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent class="py-8" title="Vented Pipe Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Vented Pipe Test Description"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/setup-vented-pipe-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/setup-vented-pipe-min.jpg" />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of completing the Vented Pipe test is to assess the
              effect of exposure of a candidate for “ammonium nitrate emulsion
              or suspension or gel, intermediate for blasting explosive” to a
              large fire under confined, vented conditions. Suitability for
              containment in portable tanks as an oxidizing substance is
              evaluated using this test.
            </p>
            <TitleComponent
              class="py-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              A wood, liquid, or gaseous fire is permitted.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objective</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Product quantity</td>
                    <td>
                      Provide a sufficient quantity of product to enable
                      characterization of any dangerous effects
                    </td>
                    <td></td>
                    <td>
                      The steel pipe is filled without tamping being careful to
                      not introduce voids
                    </td>
                  </tr>
                  <tr>
                    <td>Confining medium (steel pipe)</td>
                    <td>
                      Provide confinement and limited venting to mimic the
                      behavior of a tank
                    </td>
                    <td></td>
                    <td>
                      A steel pipe with 310 mm dia and 610 mm long with welded
                      closed with steel plates (thickness of 10 mm) on the top
                      and bottom. The top of the pipe contains a centrally
                      located hole of 78 mm in diameter to which is welded a 152
                      mm long nipple with an internal diameter of 78 mm.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Fuel type (wood, liquid, or gas), configuration, and
                      quantity
                    </td>
                    <td>
                      Create a fire of sufficient intensity and duration that
                      engulfs the product to enable characterization of any
                      dangerous effects
                    </td>
                    <td></td>
                    <td>
                      Enough fuel should be used to keep the fire burning for at
                      least 30 min or until the item(s) have clearly had enough
                      time to sufficiently react. The wood should extend beyond
                      the packages or articles by 1 m on each side. Likewise a
                      liquid or gas based fire should extend beyond the packages
                      by the same amount.
                    </td>
                  </tr>
                  <tr>
                    <td>Product placement</td>
                    <td>
                      Ensure that the packages or articles are properly heated
                      by the fire
                    </td>
                    <td></td>
                    <td>
                      A metal grid is used to support the items above the fire.
                      For a wood fire, the items should be 1 m above the ground
                      while for a fuel fire 0.5 m above the ground.
                    </td>
                  </tr>
                  <tr>
                    <td>Limited Wind Speed</td>
                    <td>
                      Ensure that all the packages or articles are enveloped in
                      flames
                    </td>
                    <td></td>
                    <td>
                      The test should not be performed under conditions where
                      the wind speed exceeds 6 m/s.
                    </td>
                  </tr>
                  <tr>
                    <td>Blast gauges, radiometers, and associated equipment</td>
                    <td>Evidence of reaction type</td>
                    <td></td>
                    <td>Optional</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Evidence of explosion</td>
                    <td>Video review and/ or post-test inspection</td>
                    <td>
                      Explosion and/or fragmentation of the pipe and/or a loud
                      noise;
                    </td>
                  </tr>
                  <tr>
                    <td>Projection of fragments from the fire area</td>
                    <td>Video camera evidence and/ or post-test inspection</td>
                    <td>Fragmentation or projection occurs</td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">
                *If an explosion and/ or fragmentation of the pipe is observed,
                then the substance should not be transported in tanks.
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="No Explosion or Fragmentation (-) Example Photo"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/nogo-vented-pipe-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/nogo-vented-pipe-min.jpg" />
            </a>
            <TitleComponent
              class="pt-8"
              title="Explosion (+) Example Photo"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/go-vented-pipe-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/go-vented-pipe-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="No Explosion or Fragmentation (-) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88943112?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88943112"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
            <TitleComponent
              class="pt-8"
              title="Explosion (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88943111?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88943111"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
